import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import diners from '../../assets/diners-icn.svg';
import discover from '../../assets/discover-icn.svg';
import mastercard from '../../assets/mastercard-icn.svg';
import visa from '../../assets/visa-icn.svg';
import { Body2 } from '../../components/Fonts/Body';
import { H4 } from '../../components/Fonts/HTags';
import Footer from '../../components/Footer';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import PlanCard, { PlanCardProps } from '../../components/PlanCard';
import { Seo } from '../../components/Seo';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import { useAuth } from '../../hooks/useAuth';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';
import { sentryCaptureException } from '../../services/sentry';
// import pse from "../../assets/pse-icn.svg";

const iconsPay = [
  mastercard,
  visa,
  diners,
  discover,
  // pse
];

const PayText = styled(Body2)`
  width: 250px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-right: 17px;
  }
`;

const PlansPage: FC = () => {
  useAuth();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPlans = async () => {
    try {
      const response = await server.get('api/v1/plans');
      setPlans(response.data.plans);
    } catch (error) {
      sentryCaptureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const goToPaymentMethod = (plan: PlanCardProps) => {
    navigate('/payment-method/', {
      state: {
        plan,
      },
    });
  };

  if (loading) {
    return (
      <Loading
        onPress={() => null}
        mainTitle="Por favor espere un momento..."
        type="loading"
        buttonLabel=""
      />
    );
  }

  return (
    <Base>
      <NavBar background="linearGradientMain">
        <H4 color="simpleWhite" fontWeight={fontWeightType.Bold}>
          Accede a todas las funciones de Carpediem
        </H4>
        <Spacer size="20" />
        <Body2 color="simpleWhite" fontWeight={fontWeightType.Medium}>
          Aquí encontrarás todo el detalle de los diferentes planes, funciones y costos de tu app
          Carpediem.
        </Body2>
        <Spacer size="20" />
        {/* <Button>Ver planes</Button> */}
      </NavBar>
      <View>
        <Spacer size="30" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Selecciona tu paquete
        </H4>
        <Spacer size="28" />
        {plans.map((plan: any) => {
          return (
            <div onClick={() => goToPaymentMethod(plan)}>
              <PlanCard key={plan.id} plan={plan} />
            </div>
          );
        })}
        <Spacer size="11" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Selecciona tu paquete
        </H4>
        <Spacer size="18" />
        <PayText color="mainGray" fontWeight={fontWeightType.Medium}>
          Tenemos estos métodos de pagos disponibles para ti:
        </PayText>
        <Spacer size="20" />
        <IconsContainer>
          {iconsPay.map((i) => {
            return <img src={i} alt={i} />;
          })}
        </IconsContainer>
        <Spacer size="31" />
      </View>
      <Footer />
    </Base>
  );
};

export default PlansPage;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Plan" />;
